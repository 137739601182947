import { Amplify } from "aws-amplify";

import type { ResourcesConfig } from "aws-amplify";

const env = {
  AWS_REGION: "eu-west-2",
  COGNITO_IDENTITY_POOL_ID:
    process.env.NEXT_PUBLIC_APP_ACCOUNT === "prod"
      ? "eu-west-2:835fe15d-faf2-4771-834a-7afe0fbf13b6"
      : "eu-west-2:c0ffe69e-9e95-404b-a6b6-f834c305ea2a",
  COGNITO_USER_POOL_ID:
    process.env.NEXT_PUBLIC_APP_ACCOUNT === "prod"
      ? "eu-west-2_fd5IR0S1n"
      : "eu-west-2_rHk5NAsLV",
  COGNITO_USER_POOL_WEB_CLIENT_ID:
    process.env.NEXT_PUBLIC_APP_ACCOUNT === "prod"
      ? "41q4hbuvn9njnsjauvlmnr2bqk"
      : "bj5s84nf5pj6478l95mftea4t",
  APPSYNC_GRAPHQL_ENDPOINT:
    process.env.NEXT_PUBLIC_APP_ACCOUNT === "prod"
      ? "https://opgywjepfza75nc5z6xn2by4ya.appsync-api.eu-west-2.amazonaws.com/graphql"
      : "https://cziaiklp2ngb5p2jfo4c53bzvi.appsync-api.eu-west-2.amazonaws.com/graphql",
};

// TODO: Generate this config from CDK stack output
// See https://appsync-immersionday.workshop.aws/lab2/1_cup-auth.html#deploy

const getTestAmplifyConfig = (): ResourcesConfig => ({
  Auth: {
    Cognito: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: "eu-west-2:3a11bbcb-ad42-4ddf-875b-c3b47ff4c10a",

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "eu-west-2_jXbohlZYZ",

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: "10cfno9t0q98d99hn3mfp1br5o",

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      allowGuestAccess: false,
    },
  },
  API: {
    GraphQL: {
      endpoint: env.APPSYNC_GRAPHQL_ENDPOINT,
      region: "eu-west-2",
      defaultAuthMode: "userPool",
    },
  },
});

export const getAmplifyConfig = (e2e_tests = false): ResourcesConfig => {
  if (e2e_tests) {
    return getTestAmplifyConfig();
  }

  return {
    Auth: {
      Cognito: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: env.COGNITO_IDENTITY_POOL_ID,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: env.COGNITO_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: env.COGNITO_USER_POOL_WEB_CLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        allowGuestAccess: false,
      },
    },
    API: {
      GraphQL: {
        endpoint: env.APPSYNC_GRAPHQL_ENDPOINT,
        region: env.AWS_REGION,
        defaultAuthMode: process.env.NODE_ENV === "test" ? "apiKey" : "userPool",
        apiKey: e2e_tests ? "fake api key because requests are mocked with msw" : undefined,
      },
    },
  };
};

const configureAmplify = (e2e_tests = false) => {
  const config = getAmplifyConfig(e2e_tests);

  Amplify.configure(config, {
    ssr: true,
    API: {
      GraphQL: {
        headers: e2e_tests
          ? async () => ({
            "x-user-origin": "e2e-tests",
            "x-e2e-test-name": process.env.E2E_TEST_NAME,
          })
          : undefined,
      },
    },
  });

  return config;
};

export default configureAmplify;
