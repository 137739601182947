import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";
import Head from "next/head";
import { useRouter } from "next/router";
import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import AppProvider, { useApp } from "../ctx/AppProvider";
import { GetClientsDocument } from "../graphql/gql-types";
import useQuery from "../hooks/useQuery";

import ChooseAccount from "../components/choose-account/ChooseAccount";
import Header from "../components/header/Header";
import Page from "../components/page/Page";
import Sidebar from "../components/sidebar/Sidebar";

import type { AppProps } from "next/app";
import type { Fullstory } from "src/lib/UtilityTypes";
import type {
  Client,
  GetClientsQuery,
  GetClientsQueryVariables,
} from "../graphql/gql-types";
import type { ServerSideProps } from "../lib/withSSRContext";

// Config
import configureAmplify from "../../configureAmplify";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import "../components/datepicker/styles.css";
import "../styles.css";
import { setCookie } from "src/lib/cookie";

declare global {
  interface Window {
    Cypress: unknown;
    FS: Fullstory;
  }
}

function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

const { publicRuntimeConfig } = getConfig() || {};

// Enable mocks (if needed) for faster or local development
const shouldUseMockAPI =
  process.env.NEXT_PUBLIC_API_MOCKING === "true" &&
  !publicRuntimeConfig.CYPRESS_CI &&
  process.env.NODE_ENV !== "test";

if (shouldUseMockAPI) {
  require("../mocks");
}

const queryClient: QueryClient = new QueryClient();

const App = ({
  Component,
  pageProps,
}: AppProps<ServerSideProps>): JSX.Element => {
  const router = useRouter();
  const pageRef = React.useRef(null);

  const useTestCognitoUserPool = !shouldUseMockAPI && pageProps.isTestUser;
  configureAmplify(useTestCognitoUserPool);

  const [user, setUser] = useApp<{ email: string }>(
    (store) => store.amplifyUser
  );

  const [activeClient, setClient] = useApp<Client>(
    (store) => store.activeClient
  );

  const { data: clients, isLoading: clientsLoading } = useQuery<
    GetClientsQuery["getClients"],
    GetClientsQuery,
    GetClientsQueryVariables
  >(
    user?.email ? GetClientsDocument : null,
    {
      email: user?.email,
    },
    [user?.email]
  );

  React.useEffect(() => {
    if (pageProps.authenticated) {
      setUser({ amplifyUser: pageProps.user });
    } else {
      setUser({ amplifyUser: null });
      setClient({ activeClient: null });
    }

    const isAuthenticated =
      pageProps.authenticated &&
      ["/login", "/authenticate"].includes(router.pathname);

    const isNotAuthenticated =
      !pageProps.authenticated &&
      !["/reset-password", "/verify"].includes(router.pathname);

    if (isNotAuthenticated) {
      router.push(pageProps.redirect || "/login");
    }

    if (isAuthenticated) {
      router.push(pageProps.redirect || "/");

      pageProps.isTestUser
        ? Sentry.setUser({ username: `e2e test: ${pageProps.e2eTestName}` })
        : Sentry.setUser({ email: pageProps.user?.email });

      if (window?.FS && user?.email) {
        window.FS.identify(user.email, {
          email: user.email,
        });
      }
    }
  }, [pageProps.authenticated, pageProps.user]);

  // Listening for how many clients we have.
  // If we have only one we're going to pick it otherwise we let the user pick
  React.useEffect(() => {
    if (clients?.length === 1) {
      setClient({
        activeClient: clients[0],
      });
      setCookie("activeClient", clients[0].cli_reference);
    }

    if (clients?.length > 1) {
      const clientRef = getCookie("activeClient");
      const activeClient = clients?.find(
        (client) => client.cli_reference === clientRef
      );

      setClient({ activeClient });
    }
  }, [clients]);

  React.useEffect(() => {
    pageProps.isTestUser
      ? Sentry.setUser({ username: `e2e test: ${pageProps.e2eTestName}` })
      : Sentry.setUser(
          activeClient ? { id: activeClient.cli_reference } : null
        );

    if (activeClient && window && window.FS && user && user.email) {
      window.FS.identify(user.email, {
        clientReference_str: activeClient.cli_reference,
        displayName: activeClient.cli_name,
        email: user.email,
      });
    }
  }, [activeClient]);

  // Setting a new client (active) by the user's choice
  if (!activeClient && clients?.length > 1) {
    return (
      <ChooseAccount
        accounts={clients}
        onAccountSelect={(clientId) => {
          setClient({
            activeClient: clients[clientId],
          });
          setCookie("activeClient", clients[clientId].cli_reference);
          router.push("/");
        }}
      />
    );
  }

  // TODO: Sort out a proper loading screen
  if (pageProps.authenticated && clientsLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Page backgroundColor={Page.Color.NONE}>
          <h1 className="m-auto">Loading...</h1>
        </Page>
      </div>
    );
  }

  // Determine whether to show the header/sidebar panels
  const shouldShowUIPanels = pageProps.authenticated && activeClient;
  return (
    <div className="flex h-screen overflow-hidden">
      <Head>
        <title>Clear Payments Platform</title>
        <link rel="icon" href="/next/assets/favicon.png" />
      </Head>

      {shouldShowUIPanels && <Sidebar />}

      <div data-ui="Page scroll container" className="flex-1 w-0 flex flex-col">
        {shouldShowUIPanels && (
          <Header showAccountSwitch={clients.length > 1} />
        )}

        <main className="flex-1 relative overflow-y-auto">
          <Component {...pageProps} ref={pageRef} />
        </main>
      </div>
    </div>
  );
};

const AppContainer = (props: AppProps<ServerSideProps>): JSX.Element => (
  <>
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <App {...props} />
        {process.env.NODE_ENV === "development" && (
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
        )}
      </QueryClientProvider>
    </AppProvider>
  </>
);

export default AppContainer;
